<template>
  <div class="grid">
    <CrudTable ref="shipment" :entities="entities"
      :meta="metaTable"
      @paging="onPaging"
      @tableAction="onTableAction"
      @itemAction="onItemAction"
      @cell-edit-complete="onCellEditComplete"/>
    <Dialog :header="header" v-model:visible="showQRCode" >
      <qrcode-vue :value="packageResource" :size="qrcodeSize" level="H" />
    </Dialog>
  <!--
    <div class="col-12 md:col-12">
      <DataTable ref="dt" :value="entities" v-model:selection="selectedEntity" dataKey="id" editMode="cell"
          @cell-edit-complete="onCellEditComplete"
          :showGridlines=true
          :paginator="true" :rows="10" :filters="filters"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} packages" responsiveLayout="scroll">
        <template #header>
          <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
            <h5 class="m-0">{{$t('package.title_list')}}</h5>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Search..." />
            </span>
          </div>
        </template>

        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="code" :header="$t('package.arrival_date')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.arrival_date')}}</span>
            {{this.formatDate(slotProps.data.arrivalDate)}}
          </template>
        </Column>
        <Column field="package_code" :header="$t('package.package_code')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.package_code')}}</span>
            {{slotProps.data.packageCode}}
          </template>
        </Column>
        <Column field="shipment_code" :header="$t('package.shipment_code')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.shipment_codes')}}</span>
            {{slotProps.data.shipmentCodes}}
          </template>
        </Column>
        <Column field="weight" :header="$t('package.weight')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.weight')}}</span>
            {{slotProps.data.weightLbs}} {{$t('unit.lbs')}} / {{tokg(slotProps.data.weightLbs)}} {{$t('unit.kg')}}
          </template>
        </Column>
        <Column field="actualWeight" :header="$t('package.actual_weight')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #editor="{ data, field }">
              <InputNumber v-model="data[field]" mode="decimal" :suffix=" $t('unit.kg')">

              </InputNumber>
          </template>
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.actual_weight')}}</span>
            {{tolbs(slotProps.data.actualWeightKg)}} {{$t('unit.lbs')}} / {{slotProps.data.actualWeightKg}} {{$t('unit.kg')}}
          </template>
        </Column>
        <Column field="storeStatus" :header="$t('package.store_status')" :sortable="true" headerStyle="width:14%; min-width:10rem;">
          <template #body="slotProps">
            <span class="p-column-title">{{$t('package.store_status')}}</span>
            {{storeStatusFormatter(slotProps.data.storeStatus)}}
          </template>
        </Column>
        <Column headerStyle="min-width:10rem;">
          <template #body="slotProps">
            <Button icon="pi pi-pencil" class="p-button-rounded mr-2" @click="openReceiptEdit(slotProps.data)" />
            <Button icon="pi pi-trash" class="p-button-rounded mt-2" @click="confirmDeleteEntity(slotProps.data)" />
          </template>
        </Column>
      </DataTable>
    </div>
          -->
  </div>

</template>
<script>
import CrudTable from '@/pages/common/CrudTable';
import PackageService from '@/service/PackageService';
import AuthService from '@/service/AuthService';
import adjustment from '@/utils/adjustment';
import formatter from '@/utils/formatter';
import {converter} from '@/utils/common';
import Consts from '@/utils/consts';
import {toBase64} from '@/utils/common';
export default {
  components: {CrudTable},
  data() {
    return {
      entities: [],
      selectedEntity: null,
      currentUser: null,
      showQRCode: false,
      packageResource: "",
      qrcodeSize: 400,
      header: this.$t('package.qrcode'),
      metaTable : {
        entityName: "package",
        expander: true,
        searchable: true,
        rowEditor: true,
        editMode: "cell",
        lazy: true,
        paginator:{
          enable: true,
          rows: 10,
          totalRecords:76,
          rowsPerPageOptions: Consts.ROW_PER_PAGE_OPTIONS,
          currentPageReportTemplate: "Showing {first} to {last} of {totalRecords} packages",
        },
        filter: {
          filterDisplay: "menu",
          globalFilterFields: ['shipmentCode','awbCode']
        },
        columns: [
          {
            name: "shippedDate",
            label: "package.shippedDate",
            headerStyle: "width:8%; min-width:8rem;",
            filter: true,
            dataType: "date",
            formatter: formatter.formatDate
          },
          {
            name: "packageCode",
            headerStyle: "width:8%; min-width:8rem;",
            filter: true,
            editor: {
              enable: true
            }
          },
          {
            name: "agentCode",
            label:"package.agentCode",
            headerStyle: "width:5%; min-width:5rem;",
            filter: true,
            condition:"user.orgType=='owner'"
          },
          {
            name: "shipmentCode",
            headerStyle: "width:10%; min-width:10rem;",
            filter: true
          },
          {
            name: "awbCode",
            headerStyle: "width:10%; min-width:10rem;",
            filter: true
          },
          {
            name: "weightLbs",
            headerStyle: "width:3%; min-width:5rem;",
            filter: true,
            dataType:"numeric",
          },
          {
            name: "weightKg",
            headerStyle: "width:3%; min-width:5rem;",
            filter: true,
            dataType:"numeric",
          },
          /*
          {
            name: "actualWeightLbs",
            headerStyle: "width:3%; min-width:6rem;",
            filter: true,
            dataType:"numeric"
          },
          {
            name: "actualWeightKg",
            headerStyle: "width:3%; min-width:6rem;",
            filter: true,
            dataType:"numeric"
          },
          */
          {
            name: "storeStatus",
            headerStyle: "width:10%; min-width:10rem;",
            filter: true,
            formatter: this.storeStatusFormatter
          }
        ],
        tableActions: [
          {
            name: "filterclear",
            icon: "pi pi-filter-slash",
            style: "p-button-outlined mr-2"
          }
        ],
        itemActions: [
          {
            name: "store",
            icon: "pi pi-table",
            style: "p-button-rounded mr-2",
            condition: "mode != 'edit'"
          },
          {
            name: "view",
            icon: "pi pi-eye",
            style: "p-button-rounded mt-2",
            condition: "mode != 'edit'"
          },
          {
            name: "qrcode",
            icon: "pi pi-qrcode",
            style: "p-button-rounded mt-2",
            condition: "mode != 'edit'"
          }
        ]
      }
    }
  },
  created() {
    this.service = PackageService;
	},
  mounted() {
    this.currentUser = AuthService.currentUser;
    PackageService.getArrivingPackages().then(data => {
      if(Array.isArray(data.content)) {
        data.content.forEach(item => adjustment.adjustPackage(item))
        this.entities = data.content;
        this.metaTable.paginator.totalRecords = data.totalElements;
      }
    });
  },
  methods: {
    formatDate(jsDate) {
      return formatter.formatDate(jsDate);
    },
    tokg(lbs) {
      return converter.tokg(lbs);
    },
    tolbs(kg) {
      return converter.tolbs(kg);
    },
    storeStatusFormatter(status) {
      return isNaN(status) ? "" : this.$t("package.store_status_" + status);
    },
    onPage(event) {
      PackageService.getArrivingPackages({
        page:event.page,
        size:event.rows
      }).then(data => {
        data.content.forEach(item => adjustment.adjustPackage(item))
        this.entities = data.content;
        this.metaTable.paginator.totalRecords = data.totalElements;
      });
    },
    onTableAction(sAction) {
      console.log(sAction)
    },
    onItemAction(sAction, entity, index) {
      if(sAction == 'store') {
        this.$router.push({ name: 'agent_shipment_edit', params: {id: entity.id} });
      } else if (sAction == 'view') {
        this.$router.push({ name: 'user_package_infos', params: {id: entity.id} });
      }
      //For testing
      else if (sAction == 'qrcode') {
        this.header = entity.packageCode;
        let resource = 'package|' + entity.packageCode;
        this.packageResource = process.env.VUE_APP_BASE_URL + '#/' + toBase64(resource);
        this.showQRCode = true;
      }
      console.log(index);
    },
    onCellEditComplete(event) {
      let { data, newValue, field } = event;
      switch (field) {
          case 'actualWeight':
              if (this.isPositiveInteger(newValue)) {
                  data[field] = newValue;
                  this.service.updateField(data.id, field, newValue);
              } else {
                  event.preventDefault();
              }
          break;

          default:
              if (newValue.trim().length > 0) {
                  data[field] = newValue;
                  this.service.updateField(data.id, field, newValue);
              } else {
                  event.preventDefault();
              }
          break;
      }
    },
    isPositiveInteger(val) {
        let str = String(val);
        str = str.trim();
        if (!str) {
            return false;
        }
        str = str.replace(/^0+/, "") || "0";
        var n = Math.floor(Number(str));
        return n !== Infinity && String(n) === str && n >= 0;
    },
  }
}
</script>
